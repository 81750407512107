/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { validate } from "uk-vehicle-number-format"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  query SalesMessageQuery {
    salesMessage: file(name: { eq: "sales-message" }) {
      props: childMarkdownRemark {
        body: html
        content: frontmatter {
          title
          registrationPlaceholder
          mileagePlaceholder
          buttonText
        }
      }
    }
  }
`

export const SalesMessage = ({ altTitle = '', altBody = '', action }) => {
  const { salesMessage: { props } } = useStaticQuery(query)
  const { body, content: { registrationPlaceholder, mileagePlaceholder, title, buttonText } } = props
  const [reg, setReg] = React.useState('')
  const [mileage, setMileage] = React.useState('')
  const [errorClass, setErrorClass] = React.useState('')
  const [requiredError, setRequiredError] = React.useState('')
  const requiredErrorClass = 'required-error'
  const setFieldValue = (e, setFunction, numberOnly = false) => {
    e.persist()
    const regEx = numberOnly ? /[^0-9]/gi : /[^0-9a-z]/gi
    const value = e.target.value.toUpperCase().replace(regEx, '')
    const thisErrorClass = !numberOnly && !validate(value) ? 'format-error ' : ''
    setErrorClass(thisErrorClass)
    setFunction(value)
  }
  const submitAction = (e) => {
    e.preventDefault()
    if (!errorClass && mileage && reg) {
      setRequiredError('')
      action({ reg, mileage })
    }
    else {
      setRequiredError(requiredErrorClass)
    }
  }

  return <>
    <div className="sales-message">
      <h2 className="-left">{altTitle || title}</h2>
      <div dangerouslySetInnerHTML={{ __html: altBody || body }}></div>
      <form onSubmit={e => submitAction(e)}>
        <p>
          <input
            id="sales-message-registration-number"
            className={`${errorClass}${reg ? '' : requiredError}`}
            maxLength="7"
            onChange={(e) => { setFieldValue(e, setReg) }}
            aria-label={registrationPlaceholder}
            placeholder={registrationPlaceholder}
            spellCheck={false}
            type="text"
            value={reg}
          />
        </p>
        <p>
          <input
            id="sales-message-mileage"
            className={mileage ? '' : requiredError}
            onChange={(e) => { setFieldValue(e, setMileage, true) }}
            aria-label={mileagePlaceholder}
            placeholder={mileagePlaceholder}
            spellCheck={false}
            inputMode="numeric"
            type="number"
            value={mileage}
          />
        </p>
        <p>
          <button type="submit" className="button button-green check-prices">{buttonText}</button>
        </p>
      </form>
    </div>
  </>
}
