/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { individualProducts } from "../util/product-mappings"
import { dataStorageKeys, setLocalStorageItem } from "../util/local-storage-wrapper"

const blueBadgePath = '/assets/site/best-value-badge.svg'
const blueBadgeAlt = 'Best Value Save over 40%'

export const calculatePercentageSaving = (individualCost, price, nearest) => {
  const cost = Number(price)
  const percentageSaving = (individualCost - cost)/individualCost * 100
  return Math.floor(percentageSaving / nearest) * nearest
}

export const calculateSeparateCosts = (quoteData) => individualProducts
  .map(p => quoteData.find(({ productCode }) => p === productCode))
  .reduce((prev, { monthlyCost }) => prev + Number(monthlyCost), 0)

const query = graphql`
  query CarefreeComponentQuery {
    content: file(name: { eq: "carefree-plan" }) {
      props: childMarkdownRemark {
        body: html
        bits: frontmatter {
          title
          badgeTitle
          badgeText
          subtitle
          bundlePrice
          combinedPrice
        }
      }
    }
  }
`

const BundleIcon = ({ name }) => {
  const fileName = name === 'Assistance'
    ? 'aa'
    : `${name.toLowerCase()}-light`

  return <div className="bundle__icon">
    <img src={`/assets/site/icons/${fileName}.svg`} alt={name} /><br />
    {name}
  </div>
}

export const CareFreeBundle = ({ data, getQuote }) => {
  const {
    content: {
      props: {
        body,
        bits: {
          title,
          badgeTitle,
          badgeText,
          subtitle,
          bundlePrice: cmsBundlePrice,
          combinedPrice: cmsCombinedPrice
        }
      }
    }
  } = useStaticQuery(query)

  const badgeContent = <>
    {badgeTitle}
    <br/>
    <strong>{badgeText}</strong>
  </>

  const packageData = data && data.length
    ? data.find(({ productCode }) => productCode === 'CPD004')
    : null

  const { monthlyCost: quoteBundlePrice = '' } = packageData || {}

  const bundlePrice = quoteBundlePrice ? `£${quoteBundlePrice}` : `from £${cmsBundlePrice}`
  const combinedPrice = quoteBundlePrice ? `£${calculateSeparateCosts(data)}` : `from £${cmsCombinedPrice}`

  const handleBuy = (e) => {
    e.preventDefault()
    if (typeof window !== 'undefined') {
      setLocalStorageItem(dataStorageKeys.selectedProduct, packageData)

      window.location.href = '../signup'
    }
  }

  return <div className="bundle">
    <div className="top-badge">
      <img src={blueBadgePath} alt={blueBadgeAlt}/>
      <div className="content">
        {badgeContent}
      </div>
    </div>
    <div className="bundle__col first">
      <h2>{title}</h2>
      <div className="icon-container">
        <BundleIcon name="Warranty" />
        <div className="bundle__plus">+</div>
        <BundleIcon name="Service" />
        <div className="bundle__plus">+</div>
        <BundleIcon name="Assistance" />
      </div>
      <div className="bundle__price">
        Bundle price: {bundlePrice}/month
      </div>
      <div className="bundle__combined">
        Regular combined price: <span className="strike">{combinedPrice}/month</span>
      </div>
    </div>
    <div className="bundle__hr">
      <hr />
    </div>
    <div className="bundle__col second">
      <div className="bundle__list-title">
        <img
          className="badge"
          align="right"
          src={blueBadgePath}
          alt={blueBadgeAlt}
        />
        <div className="badge-content">
          {badgeContent}
        </div>
        <div>
          {subtitle}
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: body }}/>
      <div className="bundle__buttons">
        {
          data && data.length
            ? <button className="button button-green" onClick={handleBuy}>Select cover</button>
            : <button className="button button-green" onClick={getQuote}>Get price</button>
        }
        <Link className="button-link" to="/carefree">
          <button className="button learn">Learn more</button>
        </Link>
      </div>
    </div>
  </div>
}
