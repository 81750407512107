/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { ProductTitle } from "./product-title"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    warranty: file(name: { eq: "warranty-plan" }) {
      props: childMarkdownRemark {
        body: html
      }
    }
    service: file(name: { eq: "service-plan" }) {
      props: childMarkdownRemark {
        body: html
      }
    }
    assistance: file(name: { eq: "assistance-plan" }) {
      props: childMarkdownRemark {
        body: html
      }
    }
    carousel: file(name: { eq: "included-carousel" }) {
      props: childMarkdownRemark {
        bits: frontmatter {
          interimServiceSchedule {
            relativePath
          }
          fullServiceSchedule {
            relativePath
          }
        }
      }
    }
  }
`

const TabHeader = ({ selectedTab, tabSelect }) => {
  const tab = 'tab'
  const tabSelected = 'tab-selected'

  return <div className="included__tabs">
    <button className={selectedTab === 0 ? tabSelected : tab} onClick={() => tabSelect(0)}>
      <ProductTitle title="Warranty" icon="warranty" />
    </button>
    <button role="tab" className={selectedTab === 1 ? tabSelected : tab} onClick={() => tabSelect(1)}>
      <ProductTitle title="Service" icon="service" />
    </button>
    <button role="tab" className={selectedTab === 2 ? tabSelected : tab} onClick={() => tabSelect(2)}>
      <ProductTitle title="Assistance" icon="assistance" />
    </button>
  </div>
}

export const WhatsIncluded = ({ pageName = '' }) => {
  const tabs = ['warranty', 'service', 'assistance']
  const defaultSelected = (pageName && tabs.includes(pageName) && tabs.indexOf(pageName)) || 0

  const [selectedTab, setSelectedTab] = React.useState(defaultSelected)
  const [imageIndex, setImageIndex] = React.useState(0)

  const data = useStaticQuery(query)

  const { [tabs[selectedTab]]: { props: { body } } } = data
  
  const { carousel : { props: { 
    bits: {
      interimServiceSchedule, 
      fullServiceSchedule 
    }
  } } } = data

  const interimServiceDocPath = '/assets/' + interimServiceSchedule.relativePath
  const fullServiceDocPath = '/assets/' + fullServiceSchedule.relativePath
  
  const tabSelect = (tabIndex) => {
    setSelectedTab(tabIndex)
  }

  const nextImage = (e) => {
    e.preventDefault()
    setImageIndex(imageIndex === 3 ? 0 : imageIndex + 1)
  }

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setImageIndex(imageIndex === 3 ? 0 : imageIndex + 1)
    }, 5000)
    return () => clearTimeout(timer)
  }, [imageIndex])

  const serviceDocs = selectedTab === 1
    ? <div>
      <a href={interimServiceDocPath} className="document-link" target="_blank" rel="noreferrer">
        <button className="button" title="Download PDF" >
          Interim Service Schedule (CPD)
        </button>
      </a>
      <a href={fullServiceDocPath} className="document-link" target="_blank" rel="noreferrer">
        <button className="button" title="Download PDF" >
          Full Service Schedule (CPD)
        </button>
      </a>
    </div>
    : null

  return <section className="included">
    <div className="included__list">
      <h3>What's included in your cover?</h3>
      <TabHeader selectedTab={selectedTab} tabSelect={tabSelect}/>
      <div className="list-content">
        <div dangerouslySetInnerHTML={{ __html: body }}/>
        {serviceDocs}
      </div>
    </div>
    <div className="included__image">
      <button onClick={nextImage}>
        <img
          width="100%"
          alt={`Included feature ${imageIndex}`}
          src={`/assets/carousel-${imageIndex + 1}-2x.png`}
        />
      </button>
    </div>
  </section>
}
