/** @jsx jsx */
import { jsx } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import { CareFreeBundle } from "./carefree-bundle"
import { FeatureProduct } from "./feature-product"
import { VehicleContent } from "./vehicle-content"
import { ViewChangeVehicle } from "./view-change-vehicle"
import { individualProducts, packageProducts } from "../util/product-mappings"
import React from "react"

const query = graphql`
  query FeatureQuery {
    safeHands: file(name: { eq: "safe-hands" }) {
      props: childMarkdownRemark {
        body: html
        bits: frontmatter {
          title
        }
      }
    }
    warranty: file(name: { eq: "warranty-plan" }) {
      props: childMarkdownRemark {
        body: html
        bits: frontmatter {
          title
          price
        }
      }
    }
    service: file(name: { eq: "service-plan" }) {
      props: childMarkdownRemark {
        body: html
        bits: frontmatter {
          title
          price
        }
      }
    }
    assistance: file(name: { eq: "assistance-plan" }) {
      props: childMarkdownRemark {
        body: html
        bits: frontmatter {
          title
          price
        }
      }
    }
  }
`

const YourPrices = ({ changeVehicle, vehicleData, pricesRef = null }) => {
  const scrollToPrices = (e) => {
    e.preventDefault()
    pricesRef && pricesRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return vehicleData
    ? <div className="your-prices">
      <div className="your-prices__col first">
        <h3>Your personalised prices</h3>
        <p>This page is now customised with <a href="#prices" onClick={(e) => scrollToPrices(e)}>personalised prices</a> based on your vehicle details</p>
        <ViewChangeVehicle clickAction={() => changeVehicle(vehicleData)}/>
      </div>
      <div className="your-prices__hr">
        <hr />
      </div>
      <div className="your-prices__col second">
        <h4>Your vehicle details</h4>
        <VehicleContent {...vehicleData} />
      </div>
    </div>
    : null
}

export const Feature = ({ quoteData, vehicleData, exclude = '', setAction, initiateQuote, featureRef }) => {
  const {
    safeHands: { props: feature },
    warranty: { props: warranty },
    service: { props: service },
    assistance: { props: assistance }
  } = useStaticQuery(query)

  const content = [warranty, service, assistance]

  const pricesRef = React.useRef(null)

  const featureProducts = packageProducts
    .map((product, i) => {
      const name = product.toLowerCase()
      const productData = quoteData && quoteData.length && quoteData
        .find(({ productCode }) => productCode === individualProducts[i])
      const contentItem = content[i]

      return { name, productData, contentItem }
    })
    .filter(({ name }) => name !== exclude)
    .map(({ name, productData, contentItem }, i) =>
      <FeatureProduct
        key={`feature-product-${i}`}
        bits={contentItem.bits}
        body={contentItem.body}
        name={name}
        data={productData}
        getQuote={initiateQuote}
      />
    )

  return <section className="features">
    <div className="full-width">
      <div className="features__content">
        <h2>{exclude ? 'Looking for something else?' : feature.bits.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: feature.body }}/>
        <div className="feature-bookmark" ref={featureRef} />
      </div>
      <div className="features__offers">
        {exclude ? null : <YourPrices changeVehicle={setAction} vehicleData={vehicleData} pricesRef={pricesRef} />}
        <div className="feature-bookmark" ref={pricesRef} />
        <div className="feature__grid">
          {featureProducts}
        </div>
        <hr className="hr-text" data-content="OR"/>
        <CareFreeBundle data={quoteData} getQuote={initiateQuote}/>
      </div>
    </div>
  </section>
}
