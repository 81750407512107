/** @jsx jsx */
import { jsx } from "theme-ui"

export const Modal = ({ content, visible = false, closeFunc }) => {
  const visibilityClass = visible ? ' modal-visible' : ''

  return <div className={`modal${visibilityClass}`}>
    <div className="modal-dialog">
      <div className="modal-content">
        <button onClick={e => closeFunc(e)} className="modal-close">X</button>
        {content}
      </div>
    </div>
  </div>
}
