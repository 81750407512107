/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { getVehicleData } from "../api/uk-vehicle-data"
import { requestAllQuotes } from "../api/cpd-product"
import { setLocalStorageItem } from "../util/local-storage-wrapper"
import { VehicleContent } from "./vehicle-content"
import { SalesMessage } from "./sales-message"
import { CpdForm, transformer } from "./cpd-form"
import { Modal } from "./modal"
import { nameMap } from "../util/product-mappings"
import { LiveChatButton } from "./live-chat-button"
import { graphql, useStaticQuery } from "gatsby"

const quoteFormFields = [
  {
    required: true,
    name: 'purchased',
    label: 'Date purchased',
    type: 'date',
    localStorageValue: 'vehicle-data.purchased'
  },
  {
    required: true,
    name: 'value',
    label: 'Estimated car value (£)',
    inputMode: 'numeric',
    transform: transformer.integer,
    localStorageValue: 'vehicle-data.value'
  }
]

export const initialModalPropsState = {
  defaultData: null,
  defaultReg: '',
  defaultMileage: '',
  defaultStep: 0,
  isActive: false
}

const salesMessageQuery = graphql`
  {
    salesMessage: file(name: { eq: "sales-message" }) {
      props: childMarkdownRemark {
        fields: frontmatter {
          yourVehicleTitle
        }
      }
    }
  }
`

const NewVehicleDetails = ({ vehicleData, mileage, reg, submitExtra, cancel, setError }) => {
  const { salesMessage: { props: { fields: { yourVehicleTitle } } } } = useStaticQuery(salesMessageQuery)
  const [data, setData] = React.useState(vehicleData)
  React.useEffect(() => {
    if (!data) {
      getVehicleData(reg).then(apiData => {
        if (apiData.error) {
          setError('Your vehicle doesn\'t appear to be in our database. ')
        }
        setData(apiData)
      })
    }
  }, [reg, data, setError])

  const submitForm = async (_, { purchased, value }) => {
    submitExtra({ ...data, value, purchased })
  }

  return <div className="vehicle-data">
    {
      data
        ? <>
          <h2>{yourVehicleTitle}</h2>
          <VehicleContent {...data} mileage={mileage || data.mileage}/>
          <CpdForm
            title=''
            fields={quoteFormFields}
            submitLabel='Yes'
            submitAction={submitForm}
            cancelLabel='No'
            cancelAction={cancel}
          />
        </>
        : <h2>Loading...</h2>
    }
  </div>
}

export const QuoteProcess = ({
  defaultData,
  isActive,
  defaultStep = -1,
  defaultMileage = '',
  defaultReg = '',
  closeModal
}) => {
  const [reg, setReg] = React.useState(defaultReg)
  const [mileage, setMileage] = React.useState(defaultMileage)
  const [step, setStep] = React.useState(defaultStep)
  const [vehicleData, setVehicleData] = React.useState(defaultData)
  const [error, setError] = React.useState(null)

  const goToStep = (step) => {
    setStep(step)
  }

  const submitReg = ({ reg, mileage }) => {
    setReg(reg)
    setMileage(mileage)
    goToStep(1)
  }

  const submitExtra = async (data) => {
    const vehicleDataWithMileage = { ...data, mileage }

    const quote = await requestAllQuotes(vehicleDataWithMileage)

    if (!quote.error && quote.every(({ monthlyCost = '' }) => !!monthlyCost)) {
      setLocalStorageItem("vehicle-data", vehicleDataWithMileage)
      const extendedQuote = Object.keys(nameMap)
        .map(p => ({ ...quote.find(({ productCode }) => p === productCode), siteLabel: nameMap[p] }))

      setLocalStorageItem("cpd-quote", extendedQuote)
      closeModal()
    }
    else {
      setError('To be eligible for cover via our website, your car must be under 10-years old, have less than 100,000-miles, and have an engine size under 2,400cc.')
    }
  }

  const resetData = () => {
    setVehicleData(null)
    setError(null)
    goToStep(0)
  }

  const getContent = (selectedStep) => {
    console.log(selectedStep, step, mileage, reg)
    if (error) {
      return <div className="vehicle-data">
        <h2>Ooops!</h2>
        <div className="error-content">
          <p>{error}</p>
          <p>We have various other products available offline, so why not speak with a member of our team over the phone or via LiveChat?</p>
          <div className="error-buttons">
            <button onClick={resetData} className="button button-electric-blue">Re-enter registration</button>
            <LiveChatButton label="Start chat" className="button-dark" />
            <button className="button button-dark">Call us</button>
          </div>
        </div>
      </div>
    }

    if (selectedStep === 0) {
      return <div className="vehicle-data">
        <SalesMessage
          action={submitReg}
          altTitle="Get an instant price"
          altBody="So we can give you an accurate quote, enter your car registration and mileage and we’ll do the rest."
        />
      </div>
    }

    if (selectedStep === 1) {
      return <NewVehicleDetails
        vehicleData={vehicleData}
        reg={reg}
        mileage={mileage}
        submitExtra={submitExtra}
        cancel={resetData}
        setError={setError}
      />
    }

    return null
  }

  return <Modal
    content={getContent(step)}
    visible={isActive}
    closeFunc={closeModal}
  />
}
