/** @jsx jsx */
import { jsx } from "theme-ui"

export const ViewChangeVehicle = ({
  clickAction,
  make = '',
  model = '',
  formattedReg = ''
}) =><span>
    {
      make && model && formattedReg
        ?<span>
          <button className="view-change-vehicle" title="view/change vehicle" onClick={clickAction}>
            <div>
            <span>{make} {model.split(" ")[0]} {model.split(" ")[1] || ""}:&nbsp;</span>
            <span>registration {formattedReg}</span>
          </div>
          </button>
          <button className="view-change-vehicle-text button button-electric-blue" title="view/change vehicle" onClick={clickAction}>
              Change your vehicle details
          </button>
        </span>
        : <button className="view-change-vehicle-text button button-electric-blue" title="view/change vehicle" onClick={clickAction}>
            Change your vehicle details
          </button>
    }
    </span>
