/** @jsx jsx */
import { jsx } from "theme-ui"

export const VehicleContent = ({
  formattedReg,
  make,
  model,
  colour,
  mileage,
  imageUrl
}) => <div className="vehicle-content">
  <div className="panel">
    <div className="row">
      <div className="label">Reg no:</div>
      <div className="content">{formattedReg}</div>
    </div>
    <div className="row">
      <div className="label">Make:</div>
      <div className="content" title={make}>{make}</div>
    </div>
    <div className="row">
      <div className="label">Model:</div>
      <div className="content" title={model}>{model}</div>
    </div>
    <div className="row">
      <div className="label">Colour:</div>
      <div className="content">{colour}</div>
    </div>
    <div className="row">
      <div className="label">Mileage:</div>
      <div className="content">{mileage}</div>
    </div>
  </div>
  <img
    className="vehicle-image"
    src={imageUrl}
    onLoad={(e) => e.target.style.display='block'}
    onError={(e) => e.target.style.display='none' }
    alt="your-car" />
</div>
