/** @jsx jsx */
import { ProductTitle } from "./product-title"
import { jsx } from "theme-ui"
import { Link } from "gatsby"

export const FeatureProduct = ({ body, bits, name, data, getQuote }) => {
  const { monthlyCost: quotePrice } = (data || {})
  const titlePrice = quotePrice ? `£${quotePrice}` : `From £${bits.price}`

  const actionButton = data
    ? <Link to={`/${name}`}>
        <button className="button price">Select cover</button>
      </Link>
    : <button className="button price" onClick={getQuote}>Get price</button>

  return <div className="feature__product">
    <div className="product-grid">
      <div className="product-content">
        <div className="product__title">
          <ProductTitle title={bits.title} icon={name} price={titlePrice} />
        </div>
        <div dangerouslySetInnerHTML={{ __html: body }}/>
      </div>
      <div className="product__buttons">
        <p>
          {actionButton}
        </p>
        <p>
          <Link to={`/${name}`}>
            <button className="button learn">Learn more</button>
          </Link>
        </p>
      </div>
    </div>
  </div>
}
